<template>
  <div class="sai-list-content">
    <ul class="sai-list-content__lists">
      <li
        class="sai-list-content__lists__item"
        v-for="(item, index) in list"
        v-if="item"
        :key="item.talkScriptId"
        :style="{ animationDelay: `${index < 20 ? 0.05 * (index + 1) : 0}s` }"
        @click="selectItem(item)"
      >
        <i class="sai-list-content__lists__item__top_icon"></i>
        <div class="sai-list-content__lists__item__wrapper">
          <div class="sai-list-content__lists__item__content">
            <div class="sai-list-content__lists__item__content__ancesters">
              <span
                class="sai-list-content__lists__item__content__ancesters__ancester"
                v-for="(ancester, index) in item.ancesters"
                :key="index"
              >
                {{ ancester.text }}
                <span v-if="index < item.ancesters.length - 1">&gt;</span>
              </span>
            </div>
            <div class="sai-list-content__lists__item__content__title">
              <div class="sai-list-content__lists__item__content__title__text">
                {{ cleanText(item.text) }}
              </div>
            </div>
          </div>
          <div
            class="sai-list-content__lists__item__category"
            v-if="existsFaqNo(item.text)"
          >
            <div class="sai-list-content__lists__item__category__text">
              <font-awesome-icon icon="tag" /> {{ generateFaqNo(item.text) }}
            </div>
          </div>
        </div>
        <div class="sai-list-content__lists__item__wrapper sp">
          <div class="sai-list-content__lists__item__content">
            <div class="sai-list-content__lists__item__content__ancesters">
              <span
                class="sai-list-content__lists__item__content__ancesters__ancester"
                v-for="(ancester, index) in item.ancesters"
                :key="index"
              >
                {{ ancester.text }}
                <span v-if="index < item.ancesters.length - 1">&gt;</span>
              </span>
            </div>
            <div
              class="sai-list-content__lists__item__category"
              v-if="existsFaqNo(item.text)"
            >
              <div class="sai-list-content__lists__item__category__text">
                <font-awesome-icon icon="tag" /> {{ generateFaqNo(item.text) }}
              </div>
            </div>
          </div>
          <div class="sai-list-content__lists__item__content__title">
            <div class="sai-list-content__lists__item__content__title__text">
              {{ cleanText(item.text) }}
            </div>
          </div>
        </div>
        <div class="sai-list-content__lists__item__icon">
          <font-awesome-icon icon="chevron-right" />
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import ListContentMixin from '@/mixins/ListContentMixin';
import textParser from '../libs/textParser';

@Component({})
export default class ListContent extends mixins(ListContentMixin) {
  cleanText(text) {
    return textParser.cleanText(text);
  }
  existsFaqNo(text) {
    return textParser.existsFaqNo(text);
  }
  generateFaqNo(text) {
    return textParser.generateFaqNo(text);
  }
}
</script>

<style lang="scss" scoped>

.sai-list-content {
  background-color: #fff;

  .custom-list & {
    padding: 0;
    height: 100%;
    border-radius: 0 0 4px 4px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;

    .sai-pc & {
      overscroll-behavior: none;
    }

    .mobile & {
      border-radius: 0;
      height: 100%;
      border-top: none;
    }
  }

  &__lists {
    .mobile & {
      font-size: 14px;
    }
    .chrome & {
      overflow-x: hidden;
    }

    &__item {
      display: flex;
      position: relative;
      align-items: center;
      padding: 12px 0;
      cursor: pointer;
      border-bottom: 1px solid #ddd;

      .chrome & {
        animation: list-fade-in 0.15s ease forwards;
        transform: translate(0, 0);

        @keyframes list-fade-in {
          0% {
            transform: translate(40px, 0);
          }
          100% {
            transform: translate(0, 0);
          }
        }
      }

      &__top_icon {
        display: block;
        position: relative;
        width: 23px;
        height: 23px;
        top: 0px;
        background: url(../resource/sai-icon_q.png) no-repeat #0057b8;
        background-size: contain;
        border-radius: 50%;

        .custom-list & {
          top: 0;
          .mobile & {
            display: none;
          }
        }
      }

      .custom-list & {
        padding: 12px 0 12px 12px;
        animation: none;
        border-bottom: 1px solid #ddd;
        .mobile & {
          transition: 0s;
          padding: 8px 8px 8px 0;
        }
        &:hover {
          .mobile & {
            color: #2c2a29;
            transform: translateX(0);
          }
        }
      }

      &__wrapper {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 0 0 12px;
        .mobile & {
          display: none;
          flex-wrap: nowrap;
        }

        &.sp {
          display: none;
          .mobile & {
            display: block;
            flex-wrap: nowrap;
          }
        }
      }

      &__content {
        flex: 1 1;
        padding-right: 18px;
        .mobile & {
          padding-right: 0px;
          width: 100%;
        }

        .mobile & {
          display: flex;
          justify-content: space-between;
        }

        &__title {
          &__text {
            font-weight: bold;
            color: #2c2a29;
            font-size: 16px;

            .custom-list & {
              .mobile & {
                font-size: 14px;
                padding: 6px 0 0 0;
              }
            }

            span {
              font-weight: 600;
              font-size: 14px;
              display: inline-block;
              .mobile & {
                font-size: 12px;
              }
              .custom-list & {
                // display: none;
              }
            }
            .sai-list-content__lists__item:hover & {
              transform: translateX(10px);
              transition: 0.08s;
              color: #0057b8;
              .mobile & {
                color: #2c2a29;
                transition: 0s;
                transform: translateX(0);
              }
            }
          }
        }
        &__ancesters {
          flex: 1 1;
          display: block;
          color: #1e90ff;
          font-size: 12px;
          &__ancester {
          }

          .sai-list-content__lists__item:hover & {
            transform: translateX(10px);
            transition: 0.08s;
            color: #0057b8;
            .mobile & {
              color: #1e90ff;
              transition: 0s;
              transform: translateX(0);
            }
          }
        }
      }
      &__category {
        padding: 0px 10px 0 0;
        .mobile & {
          padding: 0 0 0 10px;
        }
        &__text {
          text-align: right;
          font-size: 12px;
          color: #6b6969;

          svg {
            width: 12px;
            height: 12px;
            vertical-align: middle;
            margin: 0 1px 2px 0;

            .mobile & {
              margin: 0 0 1px 0;
            }
          }

          .sai-list-content__lists__item:hover & {
            color: #0057b8;
            .mobile & {
              color: #6b6969;
            }
          }
        }
      }
      &__icon {
        display: inline-block;
        height: 23px;
        width: 23px;
        padding: 3px 0 0 5px;
        border-radius: 50%;

        .custom-list & {
          margin-right: 12px;
        }

        .mobile & {
          text-align: right;
          margin-right: 0px;
          padding: 4px 0 0 0px;
          width: 18px;
        }
        svg {
          height: 14px;
          width: 14px;

          .mobile & {
            height: 12px;
            width: 12px;
          }
          path {
            fill: #6b6969;
          }
        }
        .sai-list-content__lists__item:hover & {
          background-color: #0057b8;
          svg {
            path {
              fill: #fff;
            }
          }
          .mobile & {
            background-color: #fff;
            svg {
              path {
                fill: #6b6969;
              }
            }
          }
        }
      }
    }
  }
}
</style>
