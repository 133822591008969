<template>
  <div
    class="FaqClientWindow mobile"
    ref="faqClientTag"
  >
    <div class="content">
      <div class="search" ref="taggedInput" id="sai-tag-search-input">
        <TaggedInput
          :selectedTags="selectedTags.values()"
          :searchText="searchText"
          :inputEmpty="inputEmpty"
          :filteredItems="filteredItems"
          @onTagRemoved="removeSelectedTag"
          @onSearchTextChanged="setSearchText"
          @setShow="setTagSearchShow"
        />
      </div>
    </div>
    <div class="main">
      <div class="recommend" v-show="$store.state.tagSearch.show">
        <div ref="tagList">
          <TagList
            :candidateTags="candidateTags"
            :tagListTop="tagListTop"
            :inputEmpty="inputEmpty"
            :searchText="searchText"
            @onSelectTag="addSelectedTag"
            @setShow="setTagSearchShow"
          />
        </div>
        <div class="autocomplete custom-list">
          <Autocomplete
            v-if="!inputEmpty"
            :filteredItems="filteredItems"
            @selected="selectItem"
            @setTagSearchShow="setTagSearchShow"
          />
          <Frequent v-if="inputEmpty" @setTagSearchShow="setTagSearchShow" />
        </div>
      </div>
      <ColumnNavigation />
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FaqClientTagMixin from '@/mixins/FaqClientTagMixin';
import ProductFaqClientTagMixin from '../mixins/ProductFaqClientTagMixin';

@Component({})
export default class FaqClientWindow extends mixins(
  FaqClientTagMixin,
  ProductFaqClientTagMixin
  ) {

  scrollContainer = 'body';
  scrollTopElement = '#hisApp';
  scrollFaqElement = '#sai-scenario';
  scrollAnswerElement = '#sai-scenario-answer';
  scrollSearchElement = '#sai-tag-search-input';
  autoScrollElement = '#sai-tag-search-input';
  iphoneTopElement = '#hisApp';

  autoScroll() {
    if (this.$store.state.user.isIphone) {
      return;
    }
    if (!this.$store.state.tagSearch.show) {
      this.scrollTo(this.autoScrollElement, {
        container: this.scrollContainer,
        x: false,
        duration: 500,
      });
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../style/setting/variable';

.FaqClientWindow {
  height: 100%;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-font-smoothing: subpixel-antialiased;
  font-size: 10px;
}

.head {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;

  > div {
    margin: 0 6px;
  }

  .logo {
    img {
      vertical-align: middle;
      height: 20px;
    }
  }

  .title {
    font-size: 18px;
    font-weight: bold;
  }
}

.content {
  display: block;
  position: relative;

  .header {
    padding: 15px 15px 0 15px;
    background-color: #f4f4f9;

    &__icon {
      display: inline-block;
      width: 27px;
      height: 27px;
      margin-right: 8px;
      border-radius: 50px;
      text-align: center;
      vertical-align: middle;
      background-color: #0057b8;

      img {
        width: 18px;
        height: 18px;
        margin-top: 4px;
      }
    }
    &__title {
      display: inline-block;
      vertical-align: middle;
      color: #2c2a29;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .search {
    background-color: #0057b8;
    padding: 8px;
  }
}

.recommend {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  z-index: $zIndexSp + 3;
  background-color: #fff;
  .autocomplete {
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    background-color: #fff;
  }
}

.main {
  position: relative;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}

.resultContent {
  text-align: left;
  color: #2c2a29;
  .hasScenario & {
    margin-bottom: 30px;
    padding-bottom: 30px;
  }
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(44, 42, 41, 0.5);
  outline: 0;
  z-index: $zIndexSp + 2;
}
</style>
