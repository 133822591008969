import Vue from 'vue';
import Component from 'vue-class-component';
import Const from '../const/index';

@Component({
  props: {},
})
export default class ProductFaqClientTagMixin extends Vue {
  mainTitle = Const.MAIN_TITLE;
  showDefaultHeader = Const.SHOW_DEFAULT_HEADER;
  showHeaderTitle = Const.SHOW_HEADER_TITLE;
  showHeaderIcon = Const.SHOW_HEADER_ICON;
}
