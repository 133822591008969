<template>
  <div
    class="resolveEnquete"
    v-show="currentValue && currentValue.viewType === 'result'"
  >
    <div>
      <div class="resolveButtonArea">
        <div class="buttonWrapper">
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="resolved"
          >
            {{ resolveMessage }}
          </a>
          <a
            :class="['resolveButton', showLastMessage && 'disabled']"
            @click.prevent="unresolved"
          >
            {{ unsolvedMessage }}
          </a>
        </div>
      </div>
    </div>
    <div id="sai-reset"></div>
    <div v-show="showLastMessage">
      <div class="lastMessage caption" v-html="afterFeedbackMessage"></div>
      <div class="resolveButtonArea reset">
        <a @click.prevent="reset" class="resolveButton">{{ goToTopLabel }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListFooterMixin from '@/mixins/ResourceListFooterMixin';
import Const from '../const/index';
import { eventBus } from '@/eventBus';

@Component({})
export default class ResourceListFooter extends mixins(
  ResourceListFooterMixin
) {
  resolveMessage = Const.ASK_FEEDBACK_CHOICES.RESOLVED;
  unsolvedMessage = Const.ASK_FEEDBACK_CHOICES.UNRESOLVED;
  afterResulvedMessage = Const.AFTER_RESOLVED_MESSAGE;
  afterUnResulvedMessage = Const.AFTER_UNRESOLVED_MESSAGE;
  goToTopLabel = Const.GO_TO_TOP_LABEL;

  get afterFeedbackMessage() {
    if (this.feedbackResult === 'unresolved') {
      return this.afterUnResulvedMessage;
    }
    return this.afterResulvedMessage;
  }

  async reset(e) {
    eventBus.$emit('resetWithScrollTop');
    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.generateClearTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
}
</script>

<style lang="scss" scoped>
@import '../style/settings';
@import '../style/utility/embedHtml';

.resolveButtonArea {
  padding: 18px 0;
  background-color: #ffd700;
  text-align: center;

  .mobile & {
    padding: 0px;
    background-color: #fff;

    .resolveButton {
      width: 80%;
      padding: 8px 20px;
      font-size: 14px;
      color: #fff;
      background-color: #0057b8;

      &.disabled {
        pointer-events: none;
        opacity: 0.6;
      }
    }
  }

  &.reset {
    padding: 0;
    background-color: #fff;

    .resolveButton {
      background-color: #0057b8;
      color: #fff;
    }
  }
}

.buttonWrapper {
  text-align: center;
}

.resolveButton {
  display: inline-block;
  margin: 6px;
  padding: 8px 30px;
  font-size: 16px;
  color: #2c2a29;
  text-decoration: none;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 18px;
  background-color: #fff;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    background-color: rgba(255, 255, 255, 0.8);
  }
}

.lastMessage {
  margin: 24px 0 18px 0;
  padding: 24px 12px;
  text-align: center;
  white-space: pre-wrap;
  background-color: #f4f4f9;
  border-radius: 5px;
  font-size: 16px;

  .mobile & {
    width: 80%;
    font-size: 14px;
    margin: 10px auto;
  }
}
</style>
