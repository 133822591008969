<template>
  <ListContent
    v-if="faqList.length > 0"
    :list="list"
    @selected="selected"
  />
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import { eventBus } from '@/eventBus';
import dataResource from '@/common/dataResource';
// import search from '@/common/search';
import { AsyncComputed } from '@/libs/vue-async-computed-decorator';
import axios from 'axios';
import { utilityModules } from '@/common/util';
import ListContentWrapperMixin from '@/mixins/ListContentWrapperMixin';

@Component({
  components: {},
  props: {},
})
export default class Frequent extends mixins(ListContentWrapperMixin) {
  faqList = [];
  async created() {
    const url = `${
      process.env.INITIAL_FAQ_FILE
    }?v=${utilityModules.randomNums()}`;
    const yokuaruList = await axios.get(url);
    if (!yokuaruList.data || yokuaruList.data.length === 0) {
      return;
    }

    const ids = yokuaruList.data.map(s => s.id);
    await dataResource.ready({ resourceName: 'talkScript' });
    const items = [];
    for(let i = 0; i < ids.length; i++) {
      const item = dataResource.getItemSync({
        resourceName: 'talkScript',
        talkScriptId: ids[i],
      });
      if (item) {
        items.push(item);
      }
    }
    this.faqList = items;
  }
  async selected(item) {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }
    await this.updateRoutes(item);
    if (this.$store.state.user.isSP) {
      this.$emit('setTagSearchShow', false);
    } else {
      setTimeout(() => {
        eventBus.$emit('scrollToFaqTitle');
      }, 100);
    }

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket({item: item, faqAction: 'frequent'});
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  @AsyncComputed()
  list() {
    return this.faqList;
  }
}
</script>
