<template>
  <ScrollGuide ref="scrollGuide">
    <div
      :class="[
        'ResourceList',
        active && 'active',
        active && 'pageScrollTarget',
        enableScroll && 'scrollY',
        currentValue && currentValue.viewType === 'result' && 'result',
        currentValue && currentValue.viewType === 'scenario' && 'scenario',
      ]"
      v-scroll="onScroll"
    >
      <div ref="scrollWrapper" class="scrollWrapper">
        <div class="head" v-if="currentValue.viewType !== 'talkScript'">
          <div class="title faq">
            <div class="icon"><i class="top"></i></div>
            <div class="text">
              {{ cleanText(lastScript.text) }}
            </div>
          </div>
          <div class="subInfo" v-if="existsFaqNo(lastScript.text)">
            <span>{{ generateFaqNo(lastScript.text) }}</span>
          </div>
          <div
            class="title"
            v-for="(scenario, index) in scenarios"
            :key="index"
            :class="index === scenarios.length - 1 && 'lastChoice'"
            v-if="!currentValue.talkScriptId"
          >
            <div class="icon"><i class="choice"></i></div>
            <div class="text">
              <span>{{ scenario.text }}</span>
            </div>
          </div>
        </div>
        <div
          class="caption"
          v-show="item && item.caption"
          v-html="item && item.caption"
        ></div>
        <div ref="items" class="items">
          <div
            :class="[
              'item',
              isSelected(item) && 'selected',
              'talkScriptType-' + item.talkScriptType,
              firstIndex && 'first',
            ]"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="relation">
              <div class="relation-line"></div>
            </div>
            <div
              :class="['itemContent', index === 0 && 'firstItem']"
              @click.prevent="openHandler(item)"
              :item-debug="JSON.stringify(item)"
            >
              <span>{{ item.text }}</span>
              <font-awesome-icon icon="chevron-right" />
            </div>
          </div>
        </div>
        <ResourceListFooter
          :currentValue="currentValue"
          :lastScript="lastScript"
          @scrollBottom="scrollBottom"
        />
      </div>
    </div>
    <div ref="anchor" class="anchor">
      <span class="anchorBar"></span>
      <span class="anchorBox"></span>
    </div>
  </ScrollGuide>
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import textParser from '../libs/textParser';

@Component({})
export default class ResourceList extends mixins(ResourceListMixin) {
  cleanText(text) {
    return textParser.cleanText(text);
  }
  existsFaqNo(text) {
    return textParser.existsFaqNo(text);
  }
  generateFaqNo(text) {
    return textParser.generateFaqNo(text);
  }
}
</script>
<style lang="scss" scoped>
@import '../style/settings';
@import '../style/utility/embedHtml';
$relationColor: #ccc;
$activeRelationColor: #1e90ff;

.ResourceList {
  .VerticalNavigation & {
    padding: 15px;
    overflow-y: initial;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .resultContent & {
      padding: 0;
    }
  }
  .ColumnNavigation & {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    user-select: none;
    -webkit-overflow-scrolling: touch;
  }
  img {
    display: block;
    max-width: 90%;
    margin: 0 auto;
  }
}

.result {
  .mobile & {
    padding-bottom: 40px;
  }
}

.items {
  .VerticalNavigation & {
    overflow: hidden;
  }
}

.item {
  position: relative;
  .VerticalNavigation & {
    display: block;
    float: left;
    width: 33.333%;
  }
  .ColumnNavigation & {
    &.talkScriptType-leaf {
      .itemIcon {
        height: 20px;
        width: 20px;
        display: inline-block;
        background-image: url(./../resource/n/q.png);
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}

.itemContent {
  display: flex;
  align-items: center;
  margin: 5px 0 5px 16px;
  color: #fff;
  background-color: #0057b8;
  border-radius: 4px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .scenario & {
    margin: 5px 10px 5px 25px;
  }

  .first & {
    margin: 5px 0;
    &.firstItem {
      margin: 0px 0px 5px 0px;
    }
  }

  &:hover {
    .sai-pc & {
      background-color: $activeRelationColor;
    }
  }
  &:active {
    .sai-pc & {
      background-color: $activeRelationColor;
    }
  }
  &::before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    left: 30px;
    width: 2px;
  }
  > span {
    display: block;
    flex: 1;
    padding: 14px 10px;
    font-size: 14px;
  }
  > svg {
    height: 10px;
    width: 10px;
    vertical-align: middle;
    text-align: center;
    color: #fff;
    position: absolute;
    right: 5px;
    top: 50%;
    margin-top: -5px;

    .scenario & {
      right: 14px;
    }
  }

  > i:before {
    color: #fff;
  }
  .item.selected & {
    color: #fff;
    border: 1px solid transparent;
    background-color: $activeRelationColor;
    svg {
      color: #fff;
    }
  }
  .VerticalNavigation & {
    position: relative;
    display: inline-block;
    width: 93%;
    margin-left: 8px;
    &:before {
      left: auto;
    }
  }
  .mobile & {
    font-size: 14px;
  }
}

.anchor,
.relation {
  .VerticalNavigation & {
    display: none !important;
  }
}

.anchor {
  position: absolute;
  top: 50px;
  left: 4px;
  &.selected {
    .anchorBar {
      top: -1px;
      border-width: 3px;
      border-color: $activeRelationColor;
    }
    .anchorBox {
      background: $activeRelationColor;
    }
  }
}

.anchorBox {
  position: absolute;
  top: -4px;
  width: 8px;
  height: 8px;
  background: $relationColor;
  border-radius: 4px;
}

.anchorBar {
  position: absolute;
  top: 0;
  right: -5px;
  width: 9px;
  border-top: 1px solid $relationColor;

  .selected & {
    border-top: 1px solid $activeRelationColor;
  }
}

.scrollAnchor {
  left: 10px;
  .anchorBox {
    // background: #f00;
  }
}

.ColumnNavigation {
  [ref='anchor'],
  .scrollGuide {
    display: none;
  }
}

.relation {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 8px;
  width: 8px;

  .first & {
    width: 0;
  }
  .item.selected & {
    top: -1px;
  }
  .scenario & {
    width: 17px;
  }
}
.relation-line {
  position: absolute;
  right: 0;
  left: 0;
  .item.upper & {
    top: 50%;
    bottom: auto;
    border-top: 1px solid $relationColor;
    border-left: 1px solid $relationColor;
    border-top-left-radius: 15px;
  }
  .item.lower & {
    top: auto;
    bottom: 50%;
    border-bottom: 1px solid $relationColor;
    border-left: 1px solid $relationColor;
    border-bottom-left-radius: 15px;
  }
  .item.selected & {
    border-width: 3px;
    border-color: $activeRelationColor;
    z-index: 1;
  }
}

.caption {
  white-space: pre-wrap;

  .VerticalNavigation & {
    display: block;
    margin: 24px 0;
    font-weight: bold;
    font-size: 18px;
    color: #4c6874;
    .resultContent & {
      margin-bottom: 4px;
      color: #2c2a29;
      font-weight: normal;
      font-size: 16px;
      .hasScenario & {
        color: #4c6874;
      }
    }
  }
  .ColumnNavigation & {
    padding: 10px 10px 10px 27px;
  }
  .result & {
    padding: 10px 10px 10px 22px;
  }
  .mobile & {
    font-size: 14px;
    color: #2c2a29;
  }
}

.head {
  margin: 0 10px 0 25px;
  .result & {
    margin: 5px 10px 5px 20px;
  }
  .title {
    display: flex;
    align-items: center;
    padding-bottom: 5px;

    &.faq,
    &.lastChoice {
      padding-bottom: 10px;
    }

    &.faq {
      border-bottom: 4px solid #0057b8;
      margin-bottom: 10px;
    }
    .icon {
      position: relative;
      > i {
        display: inline-block;
        position: relative;
        border-radius: 50%;
        vertical-align: middle;
        background-size: contain;
        background-repeat: no-repeat;

        &.top {
          width: 28px;
          height: 28px;
          background-color: #0057b8;
          background-image: url(../resource/sai-icon_q.png);
        }
        &.choice {
          width: 30px;
          height: 30px;
          background-image: url(./../resource/sai-icon_click.svg);
        }
      }
    }
    .text {
      display: inline-block;
      padding: 2px 0 0 10px;
      font-size: 14px;
      font-weight: bold;
    }
  }
  .subInfo {
    padding: 0 0 8px 0;
    text-align: right;
    > span {
      color: #6b6969;
    }
  }
}
</style>
