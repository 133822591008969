<template>
  <div>
    <ListContent :list="list" @selected="selected" />
  </div>
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import { eventBus } from '@/eventBus';
import ListContentWrapperMixin from '@/mixins/ListContentWrapperMixin';

@Component({
  components: {},
  props: {
    filteredItems: Array,
  },
})
export default class Autocomplete extends mixins(ListContentWrapperMixin) {
  async selected(item) {
    if (this.$store.state.user.isIphone && this.$store.state.onIME) {
      eventBus.$emit('toggleIme', false);
      return;
    }

    await this.updateRoutes(item);
    this.$emit('setTagSearchShow', false);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = await this.updateTicket({item: item, faqAction: 'search'});
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  get list() {
    return this.filteredItems;
  }
}
</script>
