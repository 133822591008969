<template>
  <div>
    <div class="sai-tagged-input" :class="{ onFocus: show }">
      <div class="sai-tagged-input__contents">
        <a
          class="sai-tagged-input__contents__tag"
          :class="tag.type === 'node' && 'category'"
          v-for="tag in selectedTags"
          :key="tag.id"
        >
          {{ tag.displayText || tag.text }}
          <a
            class="sai-tagged-input__contents__tag__close"
            @click.prevent="removeTag(tag)"
            ><font-awesome-icon icon="times" />
          </a>
        </a>
        <input
          class="sai-tagged-input__contents__input"
          type="text"
          size="1"
          :value="searchText"
          :placeholder="placeholderMessage"
          @input="customUpdateSearchText($event.target.value)"
          @focus="onFocus"
          @keyup="keyupHandler"
          @keydown="keydownHandler"
          @keypress="onKeypress"
          @compositionstart="onCompositionStart"
          @compositionend="onCompositionEnd"
          ref="searchInput"
        />
      </div>
      <div class="sai-tagged-input__functions" v-show="show">
        <div
          class="sai-tagged-input__functions__calc"
          v-if="
            filteredItems.length > 0 && (selectedTags.length > 0 || searchText)
          "
        >
          <span class="sai-tagged-input__functions__calc__count">
            <span class="sai-tagged-input__functions__calc__count__num">
              {{ filteredItems.length }}
            </span>
            <span class="sai-tagged-input__functions__calc__count__text">
              件<span>に絞り込まれています</span>
            </span>
          </span>
          <div class="sai-tagged-input__functions__calc__bar">
            <div class="sai-calc-bar" :style="{ width: barWidth + 'px' }">
              <span
                class="sai-calc-bar inner"
                :style="{
                  width: calcBarWidth(filteredItems.length) + 'px',
                }"
              ></span>
            </div>
          </div>
        </div>
        <div class="sai-tagged-input__functions__border"></div>
        <div class="sai-tagged-input__functions__close">
          <a
            class="sai-tagged-input__functions__close__icon"
            @click.prevent="closeList"
          >
            <img src="../resource/sai-icon_slim_x.png" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TaggedInputMixin from '@/mixins/TaggedInputMixin';
import Const from '../const/index';
import { eventBus } from '@/eventBus';
import dataResource from '@/common/dataResource';
import talkScript from '@/common/talkScript';

@Component({})
export default class TaggedInput extends mixins(TaggedInputMixin) {
  barWidth = 130;

  get placeholderMessage() {
    return this.$store.state.tagSearch.selectedTags.values().length === 0
      ? Const.INITIAL_INPUT_MESSAGE
      : '';
  }

  async customUpdateSearchText(val) {
    if (val.toLowerCase().indexOf('faq') === -1) {
      this.updateSearchText(val);
    } else {
      await dataResource.ready({ resourceName: 'talkScript' });
      await talkScript.ready();
      let talkScripts = await dataResource.getList({
        resourceName: 'talkScript',
      });
      talkScripts = talkScripts
        .filter(s => {
          const match = s.text.match(/\[([^\[\]]*)\]/);
          if (match) {
            const tsId = match[1];
            if (tsId.toLowerCase().indexOf(val.toLowerCase()) !== -1)
              return true;
          }
          return false;
        })
        .slice(0, 10);
      this.$store.commit('tagSearch/setSearchText', val);
      this.$store.commit('tagSearch/setFilteredItems', talkScripts);
    }
  }
  async onFocus() {
    if (this.show) {
      return;
    }
    this.setShow(true);
    if (!this.$store.state.user.isIphone) {
      setTimeout(() => {
        eventBus.$emit('scrollToSearch');
      }, 300);
    }

    if (this.$store.state.ticket.resetFlag) {
      eventBus.$emit('resetTicket');
      return;
    }
    const newTicket = this.updateOpenTicket();
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  calcBarWidth(count) {
    const length = count >= 100 ? this.barWidth : this.barWidth * (count / 100);
    return length > 2 ? length : 2;
  }
}
</script>
<style lang="scss" scoped>
@import '../style/settings';
@import '../style/component/searchBox';

.sai-tagged-input {
  display: flex;
  justify-content: space-between;
  position: relative;
  padding: 8px 12px 3px 7px;
  background-color: #fff;
  border: solid 2px #0057b8;
  border-radius: 4px;
  transition: box-shadow 0s ease 0.01s;
  margin: 0 -2px;
  min-height: 49px;

  .mobile & {
    padding: 6px 6px 1px 1px;
    border: solid 2px #0057b8;
    min-height: 46px;
  }

  &.onFocus {
    box-shadow: 0px 4px 3px -3px rgba(87, 87, 87, 0.4);
    -webkit-box-shadow: 0px 4px 3px -3px rgba(87, 87, 87, 0.4);
    -moz-box-shadow: 0px 4px 3px -3px rgba(87, 87, 87, 0.4);
    z-index: $zIndexPc + 4;
    .mobile & {
      box-shadow: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      z-index: $zIndexSp + 4;
    }
  }

  &__contents {
    flex: 1 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__tag {
      animation: tag-fade-in 0.2s;
      -ms-animation: tag-fade-in 0.2s;
      cursor: default;
      background-color: #0057b8;
      color: #fff;
      border-radius: 15px;
      padding: 4px 7px 4px 12px;
      margin: 0px 0 5px 5px;
      pointer-events: none;
      font-size: 14px;
      font-weight: 600;

      .mobile & {
        background-color: #0057b8;
        font-size: 14px;
      }

      &:hover {
        opacity: 0.6;
        -ms-opacity: 0.6;
        .mobile & {
          opacity: 1;
          -ms-opacity: 1;
        }
      }

      a {
        display: inline-block;
        height: 100%;
        padding: 0 2px 0 1px;
        cursor: pointer;
        pointer-events: auto;

        svg {
          height: 14px;
          width: 14px;
          top: 2px;
          position: relative;
          path {
            fill: rgba(255, 255, 255, 0.7);
          }
        }
      }

      &.category {
        background-color: #1e90ff;
        &:hover {
          opacity: 0.6;
          -ms-opacity: 0.6;
        }
      }
    }
    @keyframes tag-fade-in {
      0% {
        transform: scale(1);
        opacity: 0;
        -ms-opacity: 0;
      }
      50% {
        transform: scale(1.05);
        opacity: 0.8;
        -ms-opacity: 0.8;
      }
      100% {
        transform: scale(1);
        opacity: 1;
        -ms-opacity: 1;
        zoom: 1;
      }
    }

    &__input {
      display: inline-block;
      margin: 0px 0px 5px 5px;
      padding: 2px 5px;
      line-height: 24px;
      border: none;
      flex: 1;
      box-sizing: border-box;
      font-size: 16px;

      &:focus {
        outline: 0;
      }
      &::placeholder {
        color: $accentColor;
      }
      /* IE */
      &:-ms-input-placeholder {
        color: $accentColor;
      }
      /* Edge */
      &::-ms-input-placeholder {
        color: $accentColor;
      }
    }

    input[type='text']::-ms-clear {
      display: none;
    }
  }

  &__functions {
    display: flex;
    justify-content: flex-end;
    padding-top: 3px;
    .mobile & {
      display: inline-block;
      padding-top: 2px;
    }

    &__calc {
      display: inline-block;
      padding: 0px 0px 5px 5px;
      margin: 1px 0 0 5px;

      .mobile & {
        padding: 0px 0px 0px 5px;
        margin: 0;
      }

      &__count {
        display: inline-block;
        font-size: 12px;
        color: #0057b8;

        .mobile & {
          font-size: 10px;
        }

        &__num {
          display: inline-block;
          font-size: 14px;
          font-weight: bold;
        }

        &__text {
          display: inline-block;
          .mobile & {
            font-size: 12px;
          }
          span {
            .mobile & {
              display: none;
            }
          }
        }
      }

      &__bar {
        display: inline-block;
        .mobile & {
          display: none;
        }
      }
    }

    &__border {
      width: 0px;
      height: 30px;
      position: relative;
      top: -4px;
      margin-left: 12px;
      border-left: 1px solid #e8e8e8;

      .mobile & {
        display: none;
      }
    }

    &__close {
      display: inline-block;
      margin: 0 0 0 6px;

      .mobile & {
        margin: 0;
      }

      &__icon {
        display: inline-block;
        padding: 2px 3px 5px 6px;
        cursor: pointer;

        .mobile & {
          padding: 7px 4px 5px 3px;
        }

        img {
          width: 11px;
          height: 11px;
        }
      }
    }
  }
}

.sai-calc-bar {
  display: inline-block;
  position: relative;
  height: 14px;
  vertical-align: middle;
  background-color: #e8e8e8;
  background-size: 2px 2px;
  background-image: -webkit-gradient(linear, 0 0, 100% 0, color-stop(.5, #fff), color-stop(.5, transparent), to(transparent));

  &.inner {
    display: block;
    position: absolute;
    right: 0;
    transition-duration: 0.5s;
    transition-delay: 0.7s;
    transition-property: width;
    background-color: #0057b8;
  }
}

// firefox
@-moz-document url-prefix() {
  .sai-calc-bar {
    -moz-background-size: 2px 2px !important;
    background-image: -moz-linear-gradient(left, #fff 50%, transparent 50%, transparent) !important;
  }
}

// IE
@media all and (-ms-high-contrast: none) {
  *::-ms-backdrop,
  .sai-calc-bar {
    background-image: -ms-linear-gradient(left, #fff 50%, transparent 50%, transparent) !important;
  }
}
</style>
