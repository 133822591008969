<template>
  <div
    class="sai-resourceTree"
    :class="
      isBigCategory(currentValue) && isSelected(currentValue) && 'addBottom'
    "
  >
    <div
      v-if="!isRoot && currentValue && currentValue.talkScriptType === 'node'"
      class="content"
      @click.prevent="toggleItems(currentValue)"
      :class="[
        isBigCategory(currentValue) ? 'withItems' : 'withoutItems',
        isActive(currentValue) && 'active',
        isSelected(currentValue) && 'selected',
      ]"
    >
      <span class="title">
        {{ currentValue.text }}
      </span>
    </div>

    <div
      v-if="isRoot || isActive(currentValue)"
      ref="items"
      :class="[
        'items',
        isActive(currentValue) && 'active',
        isSelected(currentValue) && 'selected',
      ]"
    >
      <div
        v-for="(item, index) in list"
        :key="index"
        :class="['item', `talkScriptType-${item.talkScriptType}`]"
      >
        <ResourceTree
          :currentValue="item"
          :condition="condition"
          :isRoot="false"
          :showResult="showResult"
          :selectedCategories="selectedCategories"
          @setTagSearchShow="setTagSearchShow"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import ResourceTreeMixin from '@/mixins/ResourceTreeMixin';
import { eventBus } from '@/eventBus';

@Component({})
export default class ResourceTree extends mixins(ResourceTreeMixin) {}
</script>
<style lang="scss" scoped>
.sai-resourceTree {
  position: relative;

  &.addBottom {
    margin-bottom: 12px;
  }

  .content {
    display: inline-block;
    width: 100%;
    padding: 12px 10px;
    border-left: solid 5px transparent;
    cursor: pointer;

    &.withItems {
      &.active,
      &.selected,
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
        border-left: solid 5px #1e90ff;
      }
      &:before,
      &.selected:before {
        content: '';
        display: inline-block;
        position: absolute;
        top: 16px;
        width: 14px;
        height: 14px;
        background-size: contain;
      }
      &:before {
        background-image: url(../resource/sai-icon_plus.svg);
      }
      &.active:before,
      &.selected:before {
        background-image: url(../resource/sai-icon_minus.svg);
      }
    }

    &.withoutItems {
      &.selected,
      &:hover {
        background-color: #1e90ff;

        &::before {
          content: '#';
          position: absolute;
          left: 11px;
          margin-top: 1px;
          color: #fff;
          font-size: 15px;
        }
      }
    }

    .title {
      display: inline-block;
      color: #fff;
      font-size: 14px;
      line-height: 24px;
      padding-left: 24px;
    }
  }
  .items {
    &.active,
    &.selected {
      background-color: rgba(255, 255, 255, 0.2);
      border-left: solid 5px #1e90ff;
      .item {
        margin: 0;
      }
    }
  }
}
</style>
