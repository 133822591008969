<template>
  <div class="sai-breadcrumbs">
    <div class="sai-breadcrumbs__top">
      <div class="sai-breadcrumbs__top__icon">
        <div class="sai-breadcrumbs__top__icon__content"></div>
      </div>
      <div class="sai-breadcrumbs__top__item" @click="move(0)">
        <div class="sai-breadcrumbs__top__item__text">
          {{ topText }}
        </div>
      </div>
    </div>
    <div id="sai-bread-content" class="sai-breadcrumbs__content scrollX">
      <div class="sai-breadcrumbs__content__items">
        <div
          class="sai-breadcrumbs__content__items__item"
          :class="['active', localIndex === index && 'selected']"
          v-for="(item, index) in list"
          v-if="index > 0"
          @click="move(index)"
          :style="{ 'z-index': 1000 - index }"
          :key="index"
        >
          <a class="sai-breadcrumbs__content__items__item__text active">
            <span>
              {{ generateTitle(item.text) }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadcrumbsMixin from '@/mixins/BreadcrumbsMixin';
import Component, { mixins } from 'vue-class-component';
import Const from '../const/index';

@Component({})
export default class Breadcrumbs extends mixins(BreadcrumbsMixin) {
  scrollTarget = '.sai-breadcrumbs__content__items__item.selected';
  topText = Const.BREADCRUMB_TOP_TEXT;

  generateTitle(text) {
    return text.length > 10 ? `${text.slice(0, 10)}...` : text;
  }
}
</script>
<style lang="scss" scoped>
@import '../style/settings';

.sai-breadcrumbs {
  display: block;
  position: relative;
  height: 42px;

  .mobile & {
    margin-top: 0px;
    background-color: #fff;
  }

  &__top {
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 2px 2px 2px 0px;
    background-color: #fff;
    z-index: $zIndexSp;
    cursor: pointer;

    &::after {
      content: ' ';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      top: 1px;
      left: 100%;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-left: 11px solid #fff;
      z-index: 2;
    }

    &__icon {
      height: 100%;
      padding-right: 12px;
      padding-top: 5px;
      &__content {
        height: 28px;
        width: 28px;
        background-color: #b1b1b1;
        border-radius: 50%;
        &::before {
          content: '';
          display: inline-block;
          width: 10px;
          height: 10px;
          margin-top: 9px;
          margin-left: 11px;
          border-top: 1px solid #fff;
          border-left: 1px solid #fff;
          transform: rotate(-45deg);
        }
      }
      .mobile & {
        display: none;
      }
    }

    &__item {
      display: flex;
      align-items: center;
      position: relative;
      height: 100%;
      padding: 3px 7px;
      background-color: #f4f4f9;

      &__text {
        color: #2c2a29;
        font-weight: bold;
        font-size: 12px;

        &::after {
          content: ' ';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          top: 0px;
          left: 100%;
          border-top: 19px solid transparent;
          border-bottom: 19px solid transparent;
          border-left: 11px solid #f4f4f9;
          z-index: 3;
        }
      }
    }
  }

  &__content {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 92px;
    overflow-x: scroll;
    overflow-y: hidden;

    .mobile & {
      left: 50px;
    }

    &__items {
      display: flex;
      align-items: center;
      height: 100%;
      white-space: nowrap;

      &::after {
        content: '';
        display: block;
        clear: both;
      }

      &__item {
        position: relative;
        height: 100%;
        padding: 2px 2px 2px 0px;
        background-color: #fff;

        &::after {
          content: ' ';
          display: block;
          width: 0;
          height: 0;
          position: absolute;
          top: 1px;
          left: 100%;
          border-top: 20px solid transparent;
          border-bottom: 20px solid transparent;
          border-left: 11px solid #fff;
          z-index: 2;
        }

        &__text {
          display: flex;
          align-items: center;
          position: relative;
          height: 100%;
          padding: 3px 7px 3px 17px;
          color: #2c2a29;
          font-weight: bold;
          font-size: 12px;

          &::after {
            content: ' ';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            top: 0px;
            left: 100%;
            border-top: 19px solid transparent;
            border-bottom: 19px solid transparent;
            border-left: 11px solid #d4dfee;
            z-index: 3;
          }

          &.active {
            background: #d4dfee;
            &::after {
              border-left: 10px solid #d4dfee;
            }
          }
        }
      }
    }
  }
}
</style>
