<template>
  <ScrollGuide ref="scrollGuide">
    <div :class="['sai-resource-list', active && 'active']">
      <div class="wrapper">
        <div
          class="content"
          :class="
            item &&
              item.viewType !== 'result' &&
              isTitle(item.caption) &&
              'title'
          "
        >
          <div class="icon"></div>
          <div class="captionWrapper">
            <div
              class="caption"
              v-show="item && item.caption"
              v-html="item && item.caption"
            ></div>
          </div>
        </div>
        <div ref="items" class="items">
          <div
            :class="['item', isSelected(choice) && 'selected']"
            v-for="(choice, index) in list"
            :key="index"
          >
            <div
              class="itemContent"
              :id="`${choice.stepId}_${index}`"
              @click.prevent="openHandler(choice)"
            >
              {{ choice.text }}
            </div>
          </div>
        </div>
      </div>
      <div class="feedbackArea">
        <ResourceListFooter
          :currentValue="currentValue"
          :lastScript="lastScript"
        />
      </div>
    </div>
  </ScrollGuide>
</template>
<script>
import Component, { mixins } from 'vue-class-component';
import ResourceListMixin from '@/mixins/ResourceListMixin';
import { eventBus } from '@/eventBus';

@Component({})
export default class Scenario extends mixins(ResourceListMixin) {
  get talkScriptIndex() {
    return this.$store.getters['navigation/getRoutes'].filter(r => {return r.talkScriptId}).length - 1;
  }
  async openHandler(item) {
    this.open(item);

    this.$store.commit('ticket/setResetFlag', true);
    const newTicket = this.updateTicket(item);
    await this.$ticket.setData(newTicket);
    this.$ticket.post();
  }
  open(item) {
    this.$store.commit('navigation/open', {
      index: this.index + this.talkScriptIndex,
      route: item,
    });
  }
  updateRelation() {}
}
</script>

<style lang="scss" scoped>
@import '../style/settings';
@import '../style/utility/embedHtml';
$relationColor: #ccc;

.wrapper {
  margin-bottom: 24px;
  .answer & {
    margin-bottom: 0px;
  }

  .content {
    position: relative;
    .hasScenario & {
      border-left: none;
    }
    .icon {
      display: block;
      padding-bottom: 12px;

      .answer & {
        display: none;
      }
      &::before {
        content: '';
        display: inline-block;
        width: 40px;
        height: 40px;
        text-align: center;
        background-image: url(./../resource/sai-icon_click.svg);
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
      }
    }

    .captionWrapper {
      background-color: #f4f4f9;
      margin-left: 11px;
      padding: 12px;
      border-left: 4px solid #0057b8;
      .caption {
        padding: 26px;
        white-space: pre-wrap;
        background-color: #fff;
        img {
          margin: 10px 0;
        }
      }

      .answer & {
        margin-bottom: 0;
        border-left: 4px solid #ffd700;
      }
    }

    &.title {
      .hasScenario & {
        position: relative;
        line-height: 40px;
        padding-bottom: 12px;
        border-left: none;
        background-color: #fff;

        .icon {
          display: block;
          padding-bottom: 0;
          &::before {
            content: '';
            display: inline-block;
            position: absolute;
            width: 40px;
            height: 40px;
            text-align: center;
            background-image: url(./../resource/sai-icon_click.svg);
            background-repeat: no-repeat;
            background-size: contain;
          }
        }
        .captionWrapper {
          background-color: #fff;
          margin-left: 0px;
          padding: 0px;
          border-left: none;
          .caption {
            line-height: 40px;
            margin-left: 0;
            margin-bottom: 0;
            padding: 0 0 0 48px;
            font-size: 16px;
            font-weight: bold;
            background-color: transparent;
            border-left: none;
          }
        }
      }
    }
  }

  .items {
    display: none;
    .hasScenario & {
      display: block;
      margin-left: 11px;
      padding: 12px 12px 0 12px;
      border-left: 4px solid #0057b8;
      background-color: #f4f4f9;
    }
    .answer & {
      .hasScenario & {
        display: none;
      }
    }
  }
}

.item {
  .VerticalNavigation & {
    display: block;
    padding-bottom: 12px;
  }
  .ColumnNavigation & {
    &.talkScriptType-leaf {
      .itemIcon {
        height: 20px;
        width: 20px;
        display: inline-block;
        background-image: url(./../resource/n/q.png);
        background-repeat: no-repeat;
        background-size: contain;
        vertical-align: middle;
        margin-right: 5px;
      }
    }
  }
}

.itemContent {
  position: relative;
  width: 100%;
  padding: 8px;
  color: #fff;
  border: 1px solid transparent;
  border-radius: 5px;
  background-color: #0057b8;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  > span {
    display: block;
    padding: 5px;
  }

  &:hover {
    background-color: #1e90ff;
  }
  .mobile & {
    font-size: 14px;
  }
  .item.selected & {
    background-color: #1e90ff;
    &:hover {
      opacity: 1;
      -ms-opacity: 1;
      zoom: 1;
    }
  }
}

.feedbackArea {
  margin-left: 11px;
}

.ColumnNavigation {
  [ref='anchor'],
  .scrollGuide {
    display: none;
  }
}
</style>
