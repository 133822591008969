<template>
  <div class="sai-tag-list">
    <div class="sai-tag-list__head">
      <div class="sai-tag-list__head__title">
        <div class="sai-tag-list__head__title__text">
          {{ tagTitle }}
        </div>
      </div>
      <div class="sai-tag-list__tags" v-if="!$store.state.user.isSP">
        <a
          class="sai-tag-list__tags__tag"
          v-for="(candidate, index) in filteredCandidateTags"
          v-bind:key="index"
          v-if="index < 6 && candidate.tag"
          @click.prevent="selectTag(candidate)"
        >
          <span class="sai-tag-list__tags__tag__content">
            {{ convertTagText(candidate) }}
          </span>
        </a>
      </div>
    </div>
    <div class="sai-tag-list__tags" v-if="$store.state.user.isSP">
      <a
        class="sai-tag-list__tags__tag"
        v-for="(candidate, index) in filteredCandidateTags"
        v-bind:key="index"
        v-if="index < 6 && candidate.tag"
        @click.prevent="selectTag(candidate)"
      >
        <span class="sai-tag-list__tags__tag__content">
          {{ convertTagText(candidate) }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import TagListMixin from '@/mixins/TagListMixin';
import Const from '../const/index';

@Component({})
export default class TagList extends mixins(TagListMixin) {
  get tagTitle() {
    return this.inputEmpty ? Const.TAG_POPULAR_TITLE : Const.TAG_RECOMMEND_TITLE;
  }
}
</script>

<style lang="scss" scoped>
@import '../style/settings';

.sai-tag-list {
  padding: 12px 10px 6px 12px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #bebebe;
  min-height: 55px;

  .mobile & {
    display: flex;
    padding: 6px 6px 0px 6px;
    min-height: 43px;
  }

  &__head {
    display: flex;
    justify-content: space-between;

    &__title {
      padding: 3px 10px 5px 0;
      .mobile & {
        display: none;
      }

      &__text {
        font-size: 12px;
      }
    }

    &__close {
      .mobile & {
        display: none;
      }
      padding-top: 3px;
      &__icon {
        display: inline-block;
        padding: 2px 2px 2px 5px;
        cursor: pointer;
        .mobile & {
          padding: 0 5px 10px 10px;
        }
        img {
          width: 12px;
          height: 12px;
          .mobile & {
            width: 12px;
            height: 12px;
          }
        }
      }
    }
  }

  &__tags {
    flex: 1 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    &__tag {
      display: inline-block;
      background-color: #0057b8;
      color: #fff;
      border-radius: 15px;
      padding: 4px 10px 4px 8px;
      margin: 0 6px 6px 0;
      pointer-events: none;
      font-size: 16px;
      cursor: pointer;
      pointer-events: auto;

      .mobile & {
        background-color: #0057b8;
        padding: 2px 10px 4px 8px;
      }

      &:hover {
        opacity: 0.6;
        -ms-opacity: 0.6;
        .mobile & {
          opacity: 1;
          -ms-opacity: 1;
        }
      }

      &__content {
        font-size: 14px;
        font-weight: 600;
        display: inline-block;
      }
      &__content:before {
        content: '#';
        display: inline-block;
        color: rgba(255, 255, 255, 0.7);
        width: 10px;
        height: 15px;
        margin-right: -2px;

        .mobile & {
          width: 8px;
          height: 14px;
        }
      }
    }
  }
}
</style>
