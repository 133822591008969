// import Component from 'vue-class-component';

// /* eslint-disable import/first */

// Component.registerHooks([
//   'beforeRouteEnter',
//   'beforeRouteLeave',
//   'beforeRouteUpdate',
// ]);

import { init, domReady } from './';
import components from './components';
import config from './config';
import Const from './const';

import { library } from '@fortawesome/fontawesome-svg-core';

import { faList } from '@fortawesome/free-solid-svg-icons/faList';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';
import { faSearch } from '@fortawesome/free-solid-svg-icons/faSearch';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons/faSignOutAlt';
import { faRedoAlt } from '@fortawesome/free-solid-svg-icons/faRedoAlt';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { faTag } from '@fortawesome/free-solid-svg-icons/faTag';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import './style/index.scss';
// Customized way to call global scss
// if (!Const.USE_CUSTOMIZED_HTML) {
//   require('./style/global/index.scss');
// }
import './style/global/index.scss';

import Vue from 'vue';
import { SAITicketService } from '@/libs/sai-ticket/dist/sai-ticket.common.js';

library.add(
  faList,
  faChevronRight,
  faChevronLeft,
  faSearch,
  faSignOutAlt,
  faRedoAlt,
  faTimes,
  faTag
);
components['font-awesome-icon'] = FontAwesomeIcon;

console.log('init', { components, config });

const ticketConfig = {
  ticketInterval: 1800,
};

Vue.use(SAITicketService, {
  config: ticketConfig,
  url: process.env.TICKET_URL,
  productId: process.env.RESOURCE_ID,
});

domReady(() => {
  init({ components, config });
});
